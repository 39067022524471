.action-btn {
    cursor: pointer;
}
.action-btn:hover {
    color: #0c63ba !important ;
}
.action-btn.remove:hover {
    color: #d83535 !important;
}
.table-page-action-wrapper {
    display: flex;
    justify-content: right;
    margin: 20px 0;
}
.action-wrapper {
    display: flex;
    gap: 15px;
    
}
.ql-editor {
    height: 200px !important;
}